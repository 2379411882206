export default {
  SEARCH_BOX_CLASS: 'multi-search quickSearch',
  SEARCH_BOX_BUTTON_CLASS: 'search-button button',
  SEARCH_BOX_INPUT_CLASS: 'search-box',
  SEARCH_BOX_PLACEHOLDER: 'Search over 1M+ parts',

  BUTTON_PRIMARY_CLASS: 'button button--action',
  BUTTON_SECONDARY_CLASS: 'button button--primary',
  SELECT_CLASS: 'styled-select',

  SELECT_YOUR_VEHICLE_BLOCK: 'Shop by vehicle',

  PRODUCT_REPEATER_CLASS: 'productBlockContainer columns-4',

  GARAGE_TITLE: '<span>(</span>{{size}}<span>{{size >= 1 ? " vehicle)" : " vehicles)"}}</span>',

  FACET_TOGGLE_LABEL: 'Show Universal Parts',
};
